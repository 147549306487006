.header {
  width: fit-content;
}
.wrapper {
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 700px) {
  .wrapper {
    overflow-x: hidden;
  }
}

@media screen and (min-width: 2008px) {
  .wrapper {
    width: 2008px; /* or another specific width */
    margin-left: auto;
    margin-right: auto;
  }
}
.bottomGutter {
  width: 100%;
  height: 180px;
  position: absolute;
  z-index: -1;
  left: 0;
  margin-bottom: -55px;
}
