.content {
  max-width: 320px;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}
.header {
  font-size: 20px;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: 0.02em;
  text-align: left;
}

.footer {
  display: flex;
  gap: 10px;
}

.cta {
  border: 1.5px solid var(--AI-purple, #4305f4);
  background-color: var(--AI-purple, #4305f4) !important;
  color: white;
}
