.ImageEditingCanvasWrapper {
  & .editCanvasArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 28px;
  }

  & .toolIconsWrapper {
    max-width: fit-content;
    margin-left: 0;
    margin-right: auto;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  & .toolIconsRow {
    justify-content: flex-start;
    flex-direction: row;
    gap: 8px;
  }

  & .sliderTool {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
  }
}

[data-takt-value='tooltip-open'] button {
  display: none;
}
