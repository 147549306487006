@import 'src/globalVariables';

.container {
  box-shadow: 0px 32px 63px 11px rgba(42, 48, 53, 0.1607843137);
  margin: 0 auto;
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  color: #8290a4;
  position: relative;
  width: 100vw;
  pointer-events: auto;
  max-width: 100vw;

  @media (max-width: $mediaBreakPoint-mid) {
    width: 100%;
  }

  @media (min-width: $mediaBreakPoint-mid) {
    border-radius: 20px;
    max-width: 1024px;
    min-width: 440px;
  }

  @media (min-width: $mediaBreakPoint-sml) {
    width: 100%;
  }

  textarea {
    all: unset;
    flex-grow: 1;
    font-size: 16px;
    color: rgb(35, 47, 63);

    &::placeholder {
      font-size: 14px;
    }
  }

  .promptArea {
    background-color: var(--secondary-button-color-variant-2);
    border-radius: 12px 12px 0 0;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 10px 10px 0px 10px;

    .inputArea {
      border-radius: 8px;
      border: 2px dotted #8290a4;
      padding: 7px;
      background-color: white;
      background-size: contain;
      display: flex;
      flex-direction: column;
      align-items: center;
      aspect-ratio: 1 / 1;
      justify-content: center;
      cursor: pointer;

      .inputAreaText {
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.01em;
        text-align: left;
        white-space: nowrap;
      }
    }

    .textArea {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .effectContainer {
        display: flex;
        width: 100%;
        gap: 6px;
        flex-wrap: wrap;
        margin-top: 10px;

        .leftButton {
          display: flex;
          align-items: center;
          gap: 6px;
          padding: 6px 14px;
          background-color: #495566;
          border-radius: 17px;

          .leftButtonText {
            font-size: 13px;
            line-height: 20px;
            text-align: left;
            color: #fff;

            span {
              font-weight: 700;
            }
          }

          .leftButtonImage {
            width: 20px;
            aspect-ratio: 1 / 1;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 10px;
            margin-left: -5px;
          }
        }
      }
    }

    .sparkleIcon {
      cursor: pointer;

      &.sparkleIconEnabled {
        svg {
          fill: var(--background-color-variant-4);
        }
      }
    }

    .sparkleIconTooltip {
      width: 240px;

      .sparkleIconTooltipTitle {
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0.01em;
        text-align: left;
        margin: 5px 0;
      }

      .sparkleIconTooltipDescription {
        display: inline-block;
        margin: 5px 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.01em;
        text-align: left;
        color: var(--text-color-variant-7);
      }
    }
  }

  .negativePrompt {
    background-color: var(--secondary-button-color-variant-2);
    border-radius: 0px 0px 12px 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;

    p {
      margin: 0;
      padding-top: 5px;
      color: var(--background-color-variant-4);
      text-transform: uppercase;
      font-size: 12px;
    }

    textarea {
      margin-bottom: 5px;
    }
  }

  .buttons {
    display: flex;
    justify-content: stretch;
    margin-top: 10px;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    @media screen and (min-width: $mediaBreakPoint-sml) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    .leftButtons {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;

      @media screen and (min-width: $mediaBreakPoint-sml) {
        width: auto;
      }

      div[class^='sc-storm-ui-'] {
        width: inherit;
      }

      .dropdownList {
        display: flex;
        padding: 10px 10px;
        align-items: center;
        gap: 0.5rem;
        border-radius: 8px;
        background-color: transparent;
        box-shadow: none;
        cursor: pointer;

        &:hover {
          background-color: var(--secondary-button-color-variant-4);
          outline: rgb(94, 101, 255) solid 1px;
        }

        .icon {
          width: 32px;
          height: 32px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }

        .description {
          .title {
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 0.01em;
            text-align: left;
          }

          .subText {
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0.01em;
            text-align: left;
            color: var(--text-color-variant-7);
          }
        }
      }

      button {
        &:first-child {
          .dropdownList {
            padding: 5px;

            &:hover {
              background-color: transparent;
              outline: none;
            }

            .title {
              color: var(--text-color-variant-7);
              font-size: 13px !important;
              font-weight: 500 !important;
            }

            .icon {
              width: 20px;
              height: 20px;
            }

            .subText {
              display: none;
            }
          }
        }

        & ~ div {
          button {
            &:first-child {
              .dropdownList {
                padding: 10px 10px;
                min-width: 250px;

                &:hover {
                  background-color: var(--secondary-button-color-variant-4);
                  outline: rgb(94, 101, 255) solid 1px;
                }

                .title {
                  color: rgb(35, 47, 63);
                  font-size: 14px !important;
                  font-weight: 700 !important;
                }

                .icon {
                  width: 32px;
                  height: 32px;
                }

                .subText {
                  display: block;
                }
              }
            }
          }
        }
      }

      .seedInput {
        position: relative;
        background-color: var(--secondary-button-color-variant-2);
        border-radius: 8px;
        padding: 10px 5px 10px 50px;

        input {
          all: unset;
          width: 70px;
          font-size: 17px;
          color: var(--text-color-variant-7);
        }

        &::after {
          content: 'SEED :';
          position: absolute;
          left: 10px;
          top: 12px;
          font-size: 12px;
          color: var(--background-color-variant-4);
          pointer-events: none;
        }
      }

      .temperatureInput {
        height: 28px;

        p {
          margin: 0;
          font-size: 12px;
          line-height: 12px;

          span {
            color: var(--primary-color-2);
          }
        }
      }
    }

    .rightButtons {
      display: flex;
      gap: 20px;
      width: 100%;

      @media screen and (min-width: $mediaBreakPoint-sml) {
        width: auto;
      }

      div[class^='sc-storm-ui-'] {
        flex-direction: row;

        label {
          white-space: nowrap;
        }
      }

      .generateButtonContainer {
        width: 100%;

        button {
          p {
            margin-left: -10px;
          }
        }
      }
    }
  }

  .asinSelector {
    position: absolute;
    left: 0;
    bottom: 60px;
    width: 350px;
    z-index: 999;

    &:focus {
      outline: none;
    }
  }
}

.dropdownList {
  display: flex;
  padding: 10px 10px;
  align-items: center;
  gap: 0.5rem;
  border-radius: 8px;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;

  &.lastItem {
    margin-bottom: 2px;
  }

  &:hover {
    background-color: var(--secondary-button-color-variant-4);
    outline: rgb(94, 101, 255) solid 1px;
  }

  .icon {
    width: 32px;
    height: 32px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .description {
    .title {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0.01em;
      text-align: left;
      color: var(--text-color-variant-1);
    }

    .subText {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0.01em;
      text-align: left;
      color: var(--text-color-variant-7);
    }
  }
}

.sparkleIconTooltip {
  width: 240px;

  .sparkleIconTooltipTitle {
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.01em;
    text-align: left;
    margin: 5px 0;
  }

  .sparkleIconTooltipDescription {
    display: inline-block;
    margin: 5px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--text-color-variant-7);
  }
}
