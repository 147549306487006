.accountList {
  // modal background

  & div[role='presentation']:not(div[role='tabpanel'] div) {
    background: linear-gradient(105.82deg, rgba(98, 54, 255, 0.6) 11.04%, rgba(225, 35, 194, 0.6) 59.97%, rgba(254, 180, 36, 0.6) 100%);
    backdrop-filter: blur(10px);

    & > div:first-child {
      border-radius: 16px;
      width: var(--account-selection-width);
    }

    & > div > div > div:first-child {
      border-bottom: none;
    }
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  min-height: 200px;

  .listItem {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .listItemIcon {
      color: var(--background-color-variant-4);
    }
  }

  .progressIndicator,
  .textMessage {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

.loadMore {
  max-height: calc(65vh - 10px);
  margin-bottom: 10px;
}
