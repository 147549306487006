@import 'src/globalVariables';

.reframeWrapper {
  min-width: $studioEditControlsColumnWidth;

  .controlSection {
    margin-bottom: 20px;
  }

  .aspectRatio {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .aspectRatioItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 8px;
      gap: 7px;

      .textWrapper {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        font-family: 'Amazon Ember';
      }

      .title {
        color: var(--text-color-variant-1);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0.14px;
      }

      .subText {
        opacity: 0.95;
        color: var(--text-tertiary-inactive);

        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.12px;
      }
    }

    .selected {
      border: solid 1px var(--AI-purple);
    }

    .iconWrapper {
      width: 100%;
      max-width: 52px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        max-width: 32px;
        max-height: 30px;
      }
    }
  }

  .expandDirection {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .expandDirectionItem {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 8px;
    }

    .selected {
      border: solid 1px var(--AI-purple);
    }

    .iconWrapper {
      width: 100%;
      max-width: 55px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        max-width: 32px;
        max-height: 48px;
      }
    }
  }

  & .promptInputWrapper {
    width: 100%;
  }

  .promptInput {
    & div {
      border: none;
      max-width: 100%;
    }

    & div:focus,
    div:focus-within {
      border: none;
      outline: none;
    }

    & textarea {
      padding: 13px 16px;
      width: 100%;
      min-height: 108px;
      resize: none;
      box-sizing: border-box;
      background-color: var(--text-input-background-color);
      color: var(--text-input-text-color);
      border: none;
      border-radius: 12px;
      font-family: 'Amazon Ember';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }
}
