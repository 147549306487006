#erVhFUTiGnu5 {
    animation-name: erVhFUTiGnu5_s_do, erVhFUTiGnu5_s_da;
    animation-duration: 3000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
}
@keyframes erVhFUTiGnu5_s_do { 
    0% {stroke-dashoffset: -15.223632;animation-timing-function: cubic-bezier(0.530812,0.015205,0.807854,0.454484)}; 
    48.6% {stroke-dashoffset: -188.816319;animation-timing-function: cubic-bezier(0.3064,0.4295,0.3845,1)}; 
    100% {stroke-dashoffset: -560.639367};
}
@keyframes erVhFUTiGnu5_s_da { 
    0% {stroke-dasharray: 0, 0.09, 0.12, 545.28;animation-timing-function: cubic-bezier(0.6735,0.026,0.036,1)}; 
    48.6% {stroke-dasharray: 0, 163.65, 218.19, 163.65;animation-timing-function: cubic-bezier(0.75,0,0,1)}; 
    100% {stroke-dasharray: 0, 545.49, 0, 0};
}
    