.studioFileUploadDropzone {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 900;
  transition: opacity 0.5s ease;
  opacity: 0;
  pointer-events: none;

  &.show {
    opacity: 1;
  }

  .innerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    border-radius: 30px;
    border: 2px dashed var(--studio-core-colors-pure-white);
    color: var(--studio-core-colors-pure-white);

    i {
      font-size: 80px;
    }

    .text {
      font-feature-settings:
        'liga' off,
        'clig' off;
      font-size: 48px;
      font-weight: 700;
      font-family: 'Ember Modern Display Standard';
      letter-spacing: 2px;
      color: inherit;
    }
  }
}
