$thumbnailSize: 80px;

.imageWithThumbnails {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 20px;

  .imgWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.5s ease;

    &.show {
      opacity: 1;
    }
  }

  .loadingAnimation {
    opacity: 0;
    transition: all 0.5s ease;
    transition-property: opacity, width, height;
    max-width: 100%;
    max-height: 100%;

    &.show {
      opacity: 1;
    }

    .loadingText {
      position: absolute;
      color: white;
    }
  }

  .selectedImage {
    position: relative;
    max-width: 100%;
    transition: aspect-ratio 0.5s ease;
    overflow: hidden;

    .imgWrapper {
      position: relative;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        padding: 10px 15px;
        opacity: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(0deg, rgba(67, 5, 244, 0.3), rgba(67, 5, 244, 0.3));
        transition: opacity 0.5s ease;
      }

      &.feedbackPopoverOpen,
      &:hover {
        .overlay {
          opacity: 1;
        }
      }
    }

    .imgWrapper img,
    .imgWrapper video,
    .loadingAnimation {
      border-radius: 10px;
    }

    .imgWrapper img,
    .imgWrapper video {
      max-width: 100%;
      max-height: 50vh;
    }
  }

  .thumbnailContainer {
    display: flex;
    justify-content: center;
    width: 100%;

    .thumbnailGroups {
      display: flex;
      box-shadow: 0px 32px 63px 11px rgba(42, 48, 53, 0.1607843137);
      border-radius: 12px;
      justify-content: center;

      .thumbnailGroup {
        border-radius: 12px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 10px;
        flex-direction: column;
        align-items: center;

        .headerDetails {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 4px;
          align-self: flex-start;

          .headerIcon {
            font-size: 18px;
            color: var(--text-color-variant-8);
          }

          .headerText {
            font-size: 13px;
            line-height: 18px;
            font-weight: 400;
            color: var(--text-color-variant-8);
            font-family: 'Amazon Ember';
          }
        }

        .thumbnails {
          display: flex;
          gap: 8px;
          width: 100%;
          flex-wrap: wrap;

          .thumbnail {
            position: relative;
            width: $thumbnailSize;
            height: $thumbnailSize;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.5s ease;
            transition-property: aspect-ratio, box-shadow;
            cursor: pointer;

            .imgWrapper,
            .loadingAnimation {
              border: solid 1px #b8bfcb;
              border-radius: 8px;
              box-shadow: 0px 2px 4px 0px rgba(35, 47, 63, 0.15);
            }

            .imgWrapper {
              padding: 2px;
              background-color: white;
              width: 100%;
              height: 100%;
            }

            .imgWrapper img {
              border-radius: 6px;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            img {
              width: $thumbnailSize;
              height: $thumbnailSize;
            }

            &.selected {
              .imgWrapper {
                border: solid 2px var(--AI-purple);
                box-shadow: 0px 2px 4px 0px rgba(35, 47, 63, 0.15);
              }
            }
          }
        }
      }
    }
  }
}
