// src/AnimatedButton.scss
.animated-button-container {
  position: relative;
  display: flex;
  z-index: 1;
  width: inherit;

  &:not([aria-disabled='true'])::after {
    background: linear-gradient(90deg, #cf3eb8 0%, #6236ff 45%, #52e1bf 100%);
    transform: scale(0.9) translateY(4px);
    opacity: 0;
    filter: blur(9px);
    content: '';
    position: absolute;
    border-radius: inherit;
    inset: 0;
    z-index: -1;
    transition: opacity 0.5s ease;
  }

  &:not([aria-disabled='true']):hover::after {
    opacity: 0.7;
  }

  & div[class^='sc-storm-ui-'] {
    width: inherit;
  }
}

%button-shared {
  position: relative;
  height: 42px;
  width: 100%;
  background-color: var(--background-color-variant-4) !important;
  color: white !important;
  border: none;

  &:active {
    background-color: var(--background-color-variant-5) !important;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--background-color-variant-3) !important;
    color: gray !important;
  }
}

%animated-button-shared {
  position: relative;
  height: 42px;
  width: 100%;
  color: white !important;
  background-size: 250% 100% !important;
  animation: gradient-animation 10s linear infinite;

  // Keyframes
  @keyframes gradient-animation {
    0%,
    100% {
      background-position: 0%;
    }

    50% {
      background-position: 95%;
    }
  }
}

button.animated-button {
  @extend %button-shared;
}

button.split-animated-button {
  @extend %button-shared;
  color: white;

  &:first-of-type {
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
    padding-left: 29px;
  }

  &:last-of-type {
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
    width: auto;
  }
}

button.active-animated-button {
  &:disabled {
    @extend %animated-button-shared;
    background: linear-gradient(90deg, #cf3eb8 0%, #6236ff 45%, #52e1bf 100%);
    border: none;
  }
}

button.active-split-animated-button {
  &:first-of-type:disabled {
    @extend %animated-button-shared;
    background: linear-gradient(90deg, #cf3eb8 0%, #6236ff 50%, #52e1bf 100%);
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
    padding-left: 29px;
    border: none;
  }

  &:last-of-type:disabled {
    @extend %animated-button-shared;
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
    background: linear-gradient(90deg, #6236ff 0%, #52e1bf 70%);
    width: auto;
    border: none;
  }
}
