.productPreview {
  display: flex;
  width: 100%;
  gap: 10px;

  & .productInfo {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;

    &.center {
      justify-content: center;
    }

    & .primary {
      color: var(--Text-Base, var(--text-color, #232f3f));
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .secondary {
      color: var(--Gray-Gray-700, #5c687c);
    }
  }
}
