@import 'src/globalVariables';

.imageGrid {
  .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 4px;
    max-width: 750px;
    max-height: 40vh;
    overflow-y: auto !important;

    img {
      object-fit: cover;
      aspect-ratio: 1/1;
      border-radius: 8px;
      background: transparent;
      color: transparent; // hide with styles but keeps it on element for accessability compliance.
      z-index: 2;
      width: 100%;

      &:hover {
        cursor: pointer;
      }

      @media (max-width: $mediaBreakPoint-sml) {
        width: 100%;
      }
    }

    .buttonWrapper {
      position: relative;
      display: flex;
      border-radius: 8px;
      aspect-ratio: 1/1;
      background: none;
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
      box-shadow: none;
      min-width: 138px; // sets the width of placeholder items as well so this size was needed atm

      .placeholderWrapper {
        position: absolute;
        display: flex;
        object-fit: cover;
        aspect-ratio: 1/1;
        gap: 4px;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        z-index: 1;

        .placeholder {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        &:hover {
          cursor: pointer;
        }
      }

      @media (min-width: $mediaBreakPoint-mid) {
        width: 19%;
      }

      @media (max-width: $mediaBreakPoint-mid) {
        width: 32%;
      }

      @media (max-width: $mediaBreakPoint-sml) {
        width: 48%;
      }
    }
  }
}
