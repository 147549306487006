.copyToClipboardWrapper {
  position: relative;
  z-index: 0;
}

.copyToClipboardOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 0.8;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--AI-purple, #4305f4);
  color: var(--Color, #ffffff);
  z-index: 2;
  border-radius: 10px;
  font-family: Amazon Ember;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.copyToClipboardInvisible {
  cursor: pointer;
}

.copyToClipboardChildren {
  position: relative;
  z-index: 1;
}

.copyToClipboardOverlayText {
  display: inline-block;
  box-sizing: border-box;
  padding-left: 5px;
}

.storyExamplesBox {
  display: block;
  margin-bottom: 20px;
  border-radius: 10px;
  border-color: var(--Gray-Gray-25, #f8f9fa);
  background: var(--Gray-Gray-25, #f8f9fa);
  padding: 12px 10px;
}

.storyExamplesText {
  font-style: italic;
}
