.container {
  box-shadow: 0px 11px 19px -10px #2a303547;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;

  $contentHeight: 380px;
  .header {
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin: 5px 0 5px 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: var(--text-color-variant-8);

    .headerButton {
      cursor: pointer;
      padding: 5px 0 5px 0;

      &.selected {
        font-size: 15px;
        font-weight: 700;
        line-height: 19px;
        text-align: left;
        color: var(--text-color-variant-1);
        border-bottom: 2px solid var(--background-color-variant-4);
      }
    }
  }

  .searchBar {
    background-color: var(--secondary-button-color-variant-2);
    border-radius: 8px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;

    input {
      all: unset;
      width: 90%;
      display: block;
    }

    input:first-letter {
      text-transform: uppercase;
    }

    .searchIcon {
      cursor: pointer;
    }
  }

  .productList {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-height: 320px;
    height: calc(100vh - 360px);
    overflow-y: auto;
    overflow-x: hidden;

    .loadingSpinnerContainer {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .errorMessage {
      padding-left: 16px;
      color: rgba(201, 44, 82, 1);
      font-size: 14px;
      line-height: 21px;
    }
  }

  .tabContent {
    height: $contentHeight;
  }

  .uploadProductImage {
    width: 100%;

    div[id='drop-zone'] {
      width: 100%;
      height: 100px;
    }

    .uploadedProductImageTile {
      padding: 10px;
      height: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 12px;
      background-color: #f8f9fa;
      gap: 10px;

      .uploadedProductImageTileImagePreview {
        width: 100px;
        flex-shrink: 0;
        border-radius: 8px;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .imageDescriptionContainer {
        font-size: 13px;
        flex-grow: 1;
        font-weight: 400;
        line-height: 19.5px;
        letter-spacing: 0.01em;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;

        .imageDescriptionContainerImageName {
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .imageDescriptionContainerImageSize {
        }
      }

      .uploadProductImageCancelIcon {
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.productWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .productContainer {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    gap: 10px;

    .imageContainer {
      position: relative;
      background-color: var(--secondary-button-color-variant-2);
      padding: 2px;
      .productImage {
        width: 71px;
        aspect-ratio: 1/1;
        background-color: var(--secondary-button-color-variant-2);
        background-blend-mode: multiply;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 8px;
      }

      .productCheckbox {
        all: unset;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 4.5px;
        left: 4.5px;
        width: 16px;
        padding: 1px;
        aspect-ratio: 1/1;
        background-color: white;
        outline: #ced3dc solid 1px;
        outline-offset: -1px;
        border-radius: 4px;
        color: white;

        i {
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 12px;
            height: 12px;
          }
        }

        &.checked {
          background-color: var(--background-color-variant-4);
          outline: none;
        }
      }
    }

    .productDetails {
      display: flex;
      flex-direction: column;

      gap: 3px;

      .productDescription {
        margin-top: -4px;
        color: #232f3f;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Number of lines */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .productAsin {
        font-size: 11px;
        font-weight: 200;
        line-height: 16px;
        text-align: left;
      }

      .changeProductImage {
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        text-align: left;
        color: var(--background-color-variant-4);
      }
    }
  }

  .selectImageContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .selectImageTitle {
      color: black;
      font-size: 12px;
      letter-spacing: 0.5px;
    }

    .selectImageDescription {
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: 15px;
      text-align: left;
      color: var(--text-color-variant-7);
    }

    .selectImageImageList {
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-gap: 7.5px;

      .selectImageImageListItemContainer {
        padding: 6px;
        background-color: #edf5fb;
        border-radius: 8px;
        box-shadow: 0px 2px 4px 0px #232f3f26;
        cursor: pointer;

        &.imageSelected {
          outline: var(--background-color-variant-4) 2px solid;
          outline-offset: -2px;
          box-shadow: none;
        }

        .selectImageImageListItem {
          aspect-ratio: 1/1;
          border-radius: 3px;
          background-size: cover;
          background-position: center;
        }
      }
    }

    .selectImageCustomInput {
      all: unset;
      padding: 10px 0 10px 2px;

      .selectImageCustomLabelClickable {
        display: inline-block;
        cursor: pointer;
        color: #232f3f;
        font-size: 11px;
        font-weight: 400;
        line-height: 13.17px;
        text-align: left;
        background-color: var(--background-color-secondary);
        box-shadow: var(--box-shadow);
        padding: 5px 11px 5px 11px;
        border-radius: 999px;
      }

      input[type='file'] {
        display: none;
      }
    }
  }
}

.flexGap {
  flex-direction: column;
  gap: 10px;
}
