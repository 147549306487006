.container2 {
  position: relative;
  background-color: white;
  display: flex;
  justify-content: center;

  overflow: hidden;
  text-align: -webkit-center;

  .image {
    opacity: 1;
    display: block;
    width: 100%;
    min-height: 300px;
    object-fit: cover;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover .image {
    opacity: 0.7;
  }

  &:hover .middle {
    opacity: 1;
  }

  .text {
    color: white;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 14;
    -webkit-box-orient: vertical;
    font-weight: lighter;
    line-height: 1.2;
    width: 70%;
  }

  .leftButton {
    all: unset;
    font-size: 0.8rem;
    background-color: #0073c7;
    border-radius: 20px;
    color: white;
    padding: 7px 14px 7px 14px;
    margin-right: 10px;
    cursor: pointer;
  }

  .rightButton {
    all: unset;
    font-size: 0.8rem;
    background-color: #d9dee4;
    border-radius: 20px;
    color: black;
    padding: 7px 14px 7px 14px;
    cursor: pointer;
  }
}
