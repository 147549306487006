.hero {
  position: relative;
  max-width: 1912px;
  width: -webkit-fill-available;
  width: -moz-available;
  margin: 0 var(--div-spacing-default) 0 var(--div-spacing-default);
  align-self: flex-start;

  &.authenticated {
    height: 600px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  .hero.authenticated {
    margin: 0;
    height: 500px;
  }
}
