.textFieldLabel {
  margin: 8px 8px 8px 0px !important;
  font-size: 0.9rem !important;
}

.hideTooltipCloseIcon {
  [data-takt-value='tooltip-open'] button {
    display: none;
  }
}

.stackMenu {
  flex-direction: row;
  gap: 5px;
  Button {
    max-height: 30px;
    background-color: rgb(251, 251, 252);
  }
  .boundedBox {
    padding-top: 3px;
  }
}
