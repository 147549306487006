.buttonWithBackground {
  all: unset;
  border-radius: 8px;
  padding: 14px;
  display: flex;
  justify-content: center;
  color: white;
  font-weight: bold;
  border: none !important;
  box-shadow:
    rgba(35, 47, 63, 0.3) 0px 0px 2px 0px,
    rgba(35, 47, 63, 0.15) 0px 1px 4px 0px;
  background-size: cover !important;
  background-position-y: center !important;
  z-index: 999;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 1rem;

  span {
    padding-right: 10px;
  }

  svg {
    stroke: white;
    stroke-width: 2.5;
  }
}
