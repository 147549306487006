.wrapper {
  width: 100%;
  height: 100%;

  .header {
    padding-top: 72px;
    padding-bottom: 0px;
    width: 100%;
    text-align: start;
  }

  .imageGrid {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &::-webkit-scrollbar {
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: gray;
      border-radius: 5px;
    }

    .imageInnerGrid {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      max-width: 100%;
      gap: 10px;
      justify-content: center;
      align-items: center;
    }

    .imageGridItem {
      position: relative;
      flex: 1 1 0%;
      aspect-ratio: 1 / 1;

      .overlay {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
        aspect-ratio: 1 / 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.2);
        opacity: 0;
        transition: 0.5s ease;
        border-radius: 20px;
        border: solid 4px transparent;

        button {
          font-family: 'Amazon Ember';
          cursor: pointer;
          padding: 8px 16px 8px 16px;
          background-color: #d9dee4;
          box-shadow:
            rgba(35, 47, 63, 0.3) 0px 0px 2px 0px,
            rgba(35, 47, 63, 0.15) 0px 1px 4px 0px;
          border-radius: 16px;
          color: black;
          font-weight: 400;
        }

        &:not(:has(~ .selected)):hover {
          opacity: 1;
        }
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
        box-shadow:
          rgba(35, 47, 63, 0.3) 0px 0px 2px 0px,
          rgba(35, 47, 63, 0.15) 0px 1px 4px 0px;
      }

      .selectPlaceholder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 16px;
        aspect-ratio: 1 / 1;
      }

      .selected {
        z-index: 2;
        border: solid 4px rgb(98, 54, 255);
      }
    }
  }

  .controls {
    z-index: 999;
    position: fixed;
    bottom: var(--text-prompt-bottom-offset);
    left: 50px !important;
    right: 50px !important;

    &:has(.validationError) {
      bottom: 10px !important;
    }

    .errorText {
      position: absolute;
      top: 103%;
    }
  }

  .validationError {
  }
}
