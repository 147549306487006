// global vars
@import 'src/globalVariables';

.container {
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 10px;
  padding: 20px;
  height: 100%;
  background-color: var(--secondary-button-color-variant-2);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;

  @media (max-width: $mediaBreakPoint-mid) {
    height: calc(100% - 145px);
  }

  @media (max-width: $mediaBreakPoint-sml) {
    height: calc(100% - 190px);
  }
}
