@media (max-width: 899px) {
  .defaultFooterDivider {
    display: none;
  }

  .flexColumn {
    flex-direction: column;

    .footerLinks {
      width: 100%;
      display: flex;
      align-items: start;

      a {
        padding-bottom: 5px !important;
        padding-top: 5px !important;
      }
    }

    .copyrightText {
      width: 100%;
      padding-top: 40px;
    }
  }
}
