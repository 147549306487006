.imageModalErrorCard {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // matching aspect ratio of loading card
  aspect-ratio: 681 / 453;
  max-height: 100%;
  padding: 20px;
  border-radius: 12px;
  background-color: #6f7b8f;
  color: white;

  .errorMessage {
    display: flex;
    gap: 10px;

    .errorIcon {
      line-height: 2;
    }

    .errorText {
      display: inline-flex;
      flex-direction: column;
      width: 100%;
    }
  }

  button > i,
  button:hover > i {
    color: white;
  }
}
