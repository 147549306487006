.wrapper {
  display: flex;
  justify-content: center;
  height: 100%;

  .videoBackground {
    position: absolute;
    max-width: 1912px;
    width: 100%;
    height: 600px;
    left: 0;
    top: 0;
    border-radius: 16px;
    overflow: hidden;

    video {
      object-fit: cover;
      height: 100%;
    }
  }

  .transparentOverlay {
    position: absolute;
    max-width: 1912px;
    width: 100%;
    height: 600px;
    left: 0;
    top: 0;
    z-index: 5;
    background-color: rgba(28, 34, 85, 0.7);
    border-radius: 16px;
    overflow: hidden;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    gap: 50px;
    justify-content: center;
    z-index: 10;
    cursor: pointer;

    .title {
      font-family: Ember Modern Display Standard;
      font-size: 70px;
      font-weight: 700;
      line-height: 72.8px;
      letter-spacing: 3px;
      text-align: center;
      color: white;
    }

    .mainTitle {
      font-family: AmazonEmberMono-Bold;
      font-size: 18px;
      font-weight: 700;
      line-height: 21.55px;
      letter-spacing: 4.5px;
      text-align: center;
      color: #ffffff;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  .videoBackground,
  .transparentOverlay {
    height: 500px !important;
  }

  .content {
    gap: 30px !important;
  }

  .title {
    font-family: Ember Modern Display Standard !important;
    font-size: 50px !important;
    font-weight: 700 !important;
    line-height: 38.95px !important;
    letter-spacing: 0.015em !important;
    text-align: center !important;
  }

  .videoBackground,
  .transparentOverlay {
    border-radius: 0px !important;
  }
}

@media only screen and (max-width: 480px) {
  .title {
    font-size: 2.5rem !important;
    font-weight: 700 !important;
    line-height: 25px !important;
    letter-spacing: 0.015em !important;
    text-align: center !important;
  }
}
