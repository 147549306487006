.liveImageControlWrapper {
  & .scrollContainer {
    max-height: 60vh;
    margin-bottom: 20px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    width: calc(100% + 20px);

    & .scrollInputs {
      width: calc(100% - 20px);
    }
  }

  & .generateButtonWrapper {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 24px;

    & .generateButton {
      padding-left: 20px;
      padding-right: 0;
      min-width: 150px;
    }
  }
}
