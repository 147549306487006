// global vars
@import 'src/globalVariables';

.footerContainer {
  display: flex;
  align-items: flex-end;
  color: var(--Gray-Gray-700, #5c687c);
  padding-bottom: 55px;
  padding-left: var(--div-spacing-default);
  padding-right: var(--div-spacing-default);
  margin-top: 90px;
  width: 100%;

  @media (min-width: $mediaBreakPoint-mid) {
    height: 90px;
  }

  .footerContent {
    flex-direction: row;
    flex-wrap: no-wrap;
    align-self: auto;
    justify-content: space-between;
    width: 100%;
  }

  .footerLinks {
    flex-direction: column;
    justify-content: left;
    text-align: left;
    width: 100%;
    gap: 12px;
    margin-bottom: 32px;
    order: 1;

    @media (min-width: $mediaBreakPoint-mid) {
      flex-direction: row;
      justify-content: center;
      margin-bottom: 0;
      width: auto;
      order: unset;
    }

    p {
      width: 100%;

      @media (min-width: $mediaBreakPoint-mid) {
        width: fit-content;
      }
    }

    .footerLink {
      text-decoration: none;
      cursor: pointer;
      color: #5c687c;
    }
  }

  @media (max-width: $mediaBreakPoint-mid) {
    svg {
      display: none;
    }

    .copyright {
      order: 3;
    }

    .logo {
      order: 2;
    }
  }

  circle {
    fill: var(--text-color-variant-1);
  }
}
