.playgroundContainer {
  scrollbar-width: none; /* Firefox */
  overflow: -moz-scrollbars-none;
}

.playgroundContainerResponsive {
  display: grid !important;


  @media (max-width: 600px) {
    grid-template-columns: auto !important;
  }

  @media (min-width: 601px) and (max-width: 1000px) {
    grid-template-columns: auto auto !important;
  }

  @media (min-width: 1001px) and (max-width: 1599px) {
    grid-template-columns: auto auto auto !important;
  }

  @media (min-width: 1600px) {
    grid-template-columns: auto auto auto auto !important;
  }
}
