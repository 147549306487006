.addRemoveControlWrapper {
  & .promptInputWrapper {
    width: 100%;
    margin-bottom: 20px;
  }

  .promptInput {
    & div {
      max-width: 100%;
      border: none;
    }

    & div:focus,
    div:focus-within {
      border: none;
      outline: none;
    }

    & textarea {
      width: 100%;
      min-height: 108px;
      resize: none;
      padding: 12px;
      box-sizing: border-box;
      background-color: var(--text-input-background-color);
      color: var(--text-input-text-color);
      border: none;
      border-radius: 12px;
      font-family: 'Amazon Ember';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
    }
  }

  & .generateButtonWrapper {
    display: flex;
    justify-content: flex-end;
  }
}
