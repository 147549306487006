.contentPlaceholderBox {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
  justify-content: center;
  max-height: 28vh;

  .contentPlaceholderChild {
    position: relative;

    .image {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background-size: 100% 100% !important;
      background-repeat: no-repeat;
    }

    span {
      border-radius: 8px;
    }
  }

  .ratio_1_91_by_1 {
    height: 100%;
    aspect-ratio: 1.75/1;
  }

  .ratio_1_by_1 {
    height: 100%;
    aspect-ratio: 1/1;
  }

  .ratio_9_by_16 {
    height: 100%;
    aspect-ratio: 9/13;
  }

  .ratio_4_by_5 {
    height: 100%;
    aspect-ratio: 4/5;
  }
}

.controls {
  z-index: 999;
  width: 100%;
  margin-bottom: calc(var(--text-prompt-bottom-offset) - 1em);
  left: 50px !important;
  right: 470px !important;

  &:has(.validationError) {
    bottom: 10px !important;
  }

  .errorText {
    position: absolute;
    top: 103%;
  }
}
