.generateButton {
  all: unset;
  padding: 15px 45px;
  text-align: center;
  background: linear-gradient(90.2deg, #976aff -0.87%, #5e65ff -0.86%, #5f66ff 23.13%, #5f66ff 23.14%, #976aff 62.79%);
  box-shadow: 0px 2px 4px 0px #00000033;
  border-radius: 30px;
  color: white;
  font-size: 0.8rem;
  cursor: pointer;
  z-index: 99;
  background-size: cover !important;
  white-space: nowrap;

  &:disabled {
    background: var(--disabled-button-color);
    //opacity: 0.5;
    cursor: auto;
    color: #a0aaba;
  }
  &:hover {
    border: '2px';
    border-style: 'solid';
    border-color: '#E6E9ED';
  }
  &:active {
    border: '2px';
    border-style: 'solid';
    border-color: '#E6E9ED';
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2) inset;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

.secondaryButton {
  all: unset;
  padding: 15px 45px;
  text-align: center;
  border-radius: 30px;
  color: black;
  font-size: 0.8rem;
  cursor: pointer;
  z-index: 99;
  background-size: cover !important;
  white-space: nowrap;
  background: #f2f4f6;

  &:disabled {
    background: var(--disabled-button-color);
    opacity: 0.5;
    cursor: auto;
  }
  &:hover {
    border: '2px';
    border-style: 'solid';
    border-color: '#E6E9ED';
  }
  &:active {
    border: '2px';
    border-style: 'solid';
    border-color: '#E6E9ED';
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2) inset;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  }
}
