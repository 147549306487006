@import 'src/globalVariables';
.remixControlsWrapper {
  width: $studioEditControlsColumnWidth;

  & .promptInputWrapper {
    margin-bottom: 20px;
    width: 100%;
  }

  .promptInput {
    & div {
      border: none;
      max-width: 100%;
    }

    & div:focus,
    div:focus-within {
      border: none;
      outline: none;
    }

    & textarea {
      background-color: var(--text-input-background-color);
      border-radius: 12px;
      border: none;
      box-sizing: border-box;
      color: var(--text-input-text-color);
      font-family: 'Amazon Ember';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      min-height: 262px;
      padding: 12px;
      width: 100%;
    }
  }
}
