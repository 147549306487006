.loadingAnimation {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--AI-purple);
  overflow: hidden;
  pointer-events: none;

  .messages {
    position: absolute;
    display: flex;
    flex-direction: column;
  }

  .primaryMessage,
  .secondaryMessage {
    display: flex;
    align-items: center;
  }

  .primaryMessageText,
  .secondaryMessageText {
    color: white;
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: -100%;
    left: -100%;
    width: 400%;
    height: 400%;
    filter: blur(50px);
    animation: ColorBlobs_spin__CbrXa 8s linear infinite;
    transform-origin: 50% 50%;
    background: conic-gradient(from 90deg at 50% 50%, #2da5db, #2d90e5, #7847fc, #4753fc, #2d6edb, #7847fc, #4753fc, #4b99ef);
    background-size: 100% 100%;
    overflow: visible;
  }

  @keyframes ColorBlobs_spin__CbrXa {
    0% {
      transform: rotate(0);
    }
    50% {
      transform-origin: 60%;
    }
    100% {
      transform: rotate(1turn);
    }
  }
}
