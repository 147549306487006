@import 'src/globalVariables';

#imageGrid {
  .filterByCategoryWrapper {
    position: relative;

    .filterByCategoryButton {
      border-radius: 15px;
      border: none !important;
      box-shadow:
        rgba(35, 47, 63, 0.3) 0px 0px 2px 0px,
        rgba(35, 47, 63, 0.15) 0px 1px 4px 0px;
      font-size: 0.95rem;
      background: linear-gradient(to right, #e7f5ff, #f4fbff);
      cursor: pointer;

      .buttonContainer {
        padding: 5px 15px;
        display: flex;
        justify-content: space-between;
      }
    }

    .filterByCategoryOptions {
      position: absolute;
      width: 100%;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      border: none !important;
      box-shadow:
        rgba(35, 47, 63, 0.2) 0px 4px 8px 0px,
        rgba(35, 47, 63, 0.3) 0px 0px 2px 0px;
      font-size: 0.95rem;
      background-color: white;
      padding-bottom: 10px;
      padding-top: 10px;
      z-index: 2;

      span {
        padding: 5px 15px 5px 15px;
        cursor: pointer;

        &.selected {
          box-shadow: rgb(0, 115, 199) 3px 0px 0px inset;
          background-color: rgb(230, 233, 237);
        }

        &:not(.selected):hover {
          background-color: rgb(242, 244, 246);
        }
      }
    }
  }

  .buttomBar {
    color: white;
    position: absolute;
    bottom: 7.5px;
    left: 7.5px;
    font-weight: 600;
    font-size: 0.9rem;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .imageListItem.imageListItemSelected:hover {
    .imageListItemOverlay,
    .imageListItemCloseButton {
      visibility: visible !important;
    }
  }
}

.MuiImageListItemBar-actionIcon {
  cursor: pointer;
}

.centeredRowContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.errorMessage {
  font-size: 0.85rem;
  color: red;
}

.errorBox {
  position: relative;
  display: flex;
  margin-right: 0px;
  overflow-wrap: break-word;
  word-break: break-word;
  padding: 14px;
  border: 2px solid rgb(216, 38, 36);
  border-radius: 8px;
  background: rgb(254, 236, 236);
}

@font-face {
  font-family: 'Amazon Ember Display';
  src:
    url('https://m.media-amazon.com/images/G/01/wg/assets/fonts/AmazonEmberDisplay_W_Lt.woff2') format('woff2'),
    url('https://m.media-amazon.com/images/G/01/wg/assets/fonts/AmazonEmberDisplay_W_Lt.woff') format('woff');
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: 'Amazon Ember Display';
  src:
    url('https://m.media-amazon.com/images/G/01/wg/assets/fonts/AmazonEmberDisplay_W_Rg.woff2') format('woff2'),
    url('https://m.media-amazon.com/images/G/01/wg/assets/fonts/AmazonEmberDisplay_W_Rg.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Amazon Ember Display';
  src:
    url('https://m.media-amazon.com/images/G/01/wg/assets/fonts/AmazonEmberDisplay_W_Md.woff2') format('woff2'),
    url('https://m.media-amazon.com/images/G/01/wg/assets/fonts/AmazonEmberDisplay_W_Md.woff') format('woff');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'AmazonEmberMono-Bold';
  src: url('../assets/fonts/AmazonEmberMono_Bd.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

html,
body,
#root {
  //height: 100%;
  --default-font-family: 'Amazon Ember Display';
}

body.drag-in-progress * {
  pointer-events: none !important;
}

.mainBoxShadow {
  box-shadow:
    rgba(35, 47, 63, 0.3) 0px 0px 2px 0px,
    rgba(35, 47, 63, 0.15) 0px 1px 4px 0px;
}

#a-page,
#sc-content-container {
  height: 100%;
}

body[data-theme='main'] {
  --primary-color-1: #976aff;
  --primary-color-2: #5e65ff;
  --secondary-color-1: #ff833e;
  --secondary-color-2: #f1f2ff;
  --text-color-variant-1: #232f3f;
  --text-color-variant-2: #495456;
  --text-color-variant-3: #d8d8d8;
  --text-color-variant-4: #e6e9ed;
  --text-color-variant-5: #e6e9ed;
  --text-color-variant-6: #e6e9ed;
  --text-color-variant-7: #5c687c;
  --text-color-variant-8: #495566;
  --secondary-button-color-variant-1: #fbfbfb;
  --secondary-button-color-variant-2: #f2f4f6;
  --secondary-button-color-variant-3: #f2f4f6;
  --secondary-button-color-variant-4: #f8f9fa;
  --background-color-variant-1: #f9fbfd;
  --background-color-secondary: rgb(217, 222, 228);
  --background-color-variant-2: #ffffff;
  --background-color-variant-3: #e6e9ed;
  --background-color-variant-4: #4305f4;
  --background-color-variant-5: #230091;
  --text-tertiary-inactive: #5c687c;
  --text-input-background-color: #f2f4f6;
  --text-input-text-color: #6f7b8f;
  --disabled-button-color: #e6e9ed;
  --color-gray-100: #e6e9ed;
  --color-gray-150: #d9dee4;
  --box-shadow: rgba(35, 47, 63, 0.3) 0px 0px 2px 0px, rgba(35, 47, 63, 0.15) 0px 1px 4px 0px;

  /* colour naming using in Figma */
  --AI-purple: var(--background-color-variant-4, #4305f4);

  /* new UI colours */
  --background-level-1: #000000;
  --background-level-2: #f5f3ef;
  --background-level-2-Reverse: #454a4f;
  --background-level-3: #aaabac;
  --surface-offset: #f5f3ef;
  --surface-subtleOffset: #f9f8f6;
  --studio-core-colors-pure-white: #ffffff;
  --text-ui-primary: #161d26;
  --textColor-secondary: #2d3239;
  --text-ui-white-on-color-background: #ffffff;

  /********* Button colors **********/

  // TODO: clean these up if no longer needed
  --primaryButtonColor-disable: #aaabac;
  --primaryButtonColor-textDisabled: #2d3239;
  --standard-button-color-light: #ffffff;
  --standard-button-color-dark: #161d26;
  --standard-button-hover-light: #f5f3ef;
  --standard-button-hover-dark: #454a4f;
  --standard-button-icon-color: #2d3239;
  --standard-button-disabled: #aaabac;
  --standard-button-disabled-light: #676b6f;

  // button - default - light mode (primary)
  --button-default-text: #ffffff;
  --button-default-background: #161d26;
  --button-default-hover-text: #ffffff;
  --button-default-hover-background: #161d26;
  --button-default-active-text: #ffffff;
  --button-default-active-background: #000000;
  --button-default-unavailable-text: #2d3239;
  --button-default-unavailable-background: #aaabac;

  // button - default - dark mode (primary)
  --button-default-dark-text: #161d26;
  --button-default-dark-background: #ffffff;
  --button-default-dark-hover-text: #161d26;
  --button-default-dark-hover-background: #f5f3ef;
  --button-default-dark-active-text: #161d26;
  --button-default-dark-active-background: #f5f3ef;
  --button-default-dark-unavailable-text: #2d3239;
  --button-default-dark-unavailable-background: #aaabac;

  // button - secondary - light mode
  --button-secondary-text: #2d3239;
  --button-secondary-background: transparent;
  --button-secondary-border: #2d3239;
  --button-secondary-hover-text: #2d3239;
  --button-secondary-hover-background: #f5f3ef;
  --button-secondary-hover-border: #2d3239;
  --button-secondary-active-text: #2d3239;
  --button-secondary-active-background: #f9f8f6;
  --button-secondary-active-border: #2d3239;
  --button-secondary-unavailable-text: #676b6f;
  --button-secondary-unavailable-background: transparent;
  --button-secondary-unavailable-border: #aaabac;

  // button - secondary - dark mode
  --button-secondary-dark-text: #f5f3ef;
  --button-secondary-dark-background: transparent;
  --button-secondary-dark-border: #f5f3ef;
  --button-secondary-dark-hover-text: #f5f3ef;
  --button-secondary-dark-hover-background: #454a4f;
  --button-secondary-dark-hover-border: #f5f3ef;
  --button-secondary-dark-active-text: #f5f3ef;
  --button-secondary-dark-active-background: #2d3239;
  --button-secondary-dark-active-border: #f5f3ef;
  --button-secondary-dark-unavailable-text: #b7b8b8;
  --button-secondary-dark-unavailable-background: transparent;
  --button-secondary-dark-unavailable-border: #8a8d8f;

  // button - tertiary - light mode
  --button-tertiary-text: #4305f4;
  --button-tertiary-background: transparent;
  --button-tertiary-hover-text: #2e02ad;
  --button-tertiary-hover-background: #f5f3ef;
  --button-tertiary-active-text: #4305f4;
  --button-tertiary-active-background: #f9f8f6;
  --button-tertiary-unavailable-text: #676b6f;
  --button-tertiary-unavailable-background: transparent;

  // button - tertiary - dark mode
  --button-tertiary-dark-text: #b38aff;
  --button-tertiary-dark-background: transparent;
  --button-tertiary-dark-hover-text: #caadff;
  --button-tertiary-dark-hover-background: #454a4f;
  --button-tertiary-dark-active-text: #b38aff;
  --button-tertiary-dark-active-background: #2d3239;
  --button-tertiary-dark-unavailable-text: #b7b8b8;
  --button-tertiary-dark-unavailable-background: transparent;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  :root {
    --text-prompt-horizontal-offset: 20px;
    --text-prompt-bottom-offset: 20px;
    --div-spacing-default: 20px;
    --account-selection-width: 90%;
    --tools-title-padding-bottom: 16px;
    --tools-description-container-margin-bottom: 24px;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 768px) {
  :root {
    --text-prompt-horizontal-offset: 20px;
    --text-prompt-bottom-offset: 20px;
    --div-spacing-default: 44px;
    --account-selection-width: 70%;
    --tools-title-padding-bottom: 20px;
    --tools-description-container-margin-bottom: 44px;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 769px) and (max-width: 1024px) {
  :root {
    --text-prompt-horizontal-offset: 5%;
    --text-prompt-bottom-offset: 44px;
    --div-spacing-default: 44px;
    --account-selection-width: 50%;
    --tools-title-padding-bottom: 20px;
    --tools-description-container-margin-bottom: 44px;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  :root {
    --text-prompt-horizontal-offset: 5%;
    --text-prompt-bottom-offset: 44px;
    --div-spacing-default: 44px;
    --account-selection-width: 40%;
    --tools-title-padding-bottom: 20px;
    --tools-description-container-margin-bottom: 44px;
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  :root {
    --text-prompt-horizontal-offset: 17%;
    --text-prompt-bottom-offset: 44px;
    --div-spacing-default: 44px;
    --account-selection-width: 40%;
    --tools-title-padding-bottom: 20px;
    --tools-description-container-margin-bottom: 44px;
  }
}

@media (min-width: 1440px) {
  #homepage,
  #homepage-banner-text {
    max-width: 1282px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.page-margin {
  margin-left: var(--div-spacing-default) !important;
  margin-right: var(--div-spacing-default) !important;
  //  margin-right: 0 !important;
}

.page-padding {
  padding-left: var(--div-spacing-default) !important;
  padding-right: var(--div-spacing-default) !important;
}

.home-content-sizing {
  width: auto !important;
  padding: 0 !important;
  margin-left: var(--div-spacing-default) !important;
  margin-right: var(--div-spacing-default) !important;
  margin-top: calc(1.3 * var(--div-spacing-default));
}

.hide-text-overflow-2lines {
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hide-text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fileUploadCloseButton {
  width: 17px !important;
  height: 17px !important;
  top: 5px !important;
  right: 5px !important;
  cursor: pointer;
}

.fileUploadShadeOverlay {
  border-radius: 7px !important;

  &:hover {
    cursor: pointer;
  }
}

#portal > [role='presentation'] {
  background: linear-gradient(106deg, rgba(98, 54, 255, 0.6) 11.04%, rgba(225, 35, 194, 0.6) 59.97%, rgba(254, 180, 36, 0.6) 100%);
  backdrop-filter: blur(10px);
}

// required to override storm ui modal module max sizing (and not affect other modal modules within as per ID list below)
#studio-preview-modal div[role='presentation']:not(#feedback-comment-modal, #choose-account-modal) > div {
  width: 95%;
  max-width: 95%;
  border-radius: 20px;
  max-height: calc(100vh - 100px);
  margin-bottom: 70px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: $mediaBreakPoint-sml) {
    width: 80%;
    max-width: 80%;
    margin-bottom: unset;
  }

  @media (min-width: $mediaBreakPoint-lrg) {
    width: 90%;
    max-width: 90%;
  }
}

// needed to override storm UI `FileUploadDropZone` component styles
#drop-zone-playground {
  background: #f5f3ef;
  border-radius: 10px;

  label {
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    gap: 10px;
    padding: 24px 20px;

    div {
      margin-top: 0;
    }
  }
}

// needed to override modal size to full width on mobile breakpoint for sandbox feeds implementation(s)
#dropzone-modal div[role='presentation']:not(#feedback-comment-modal, #choose-account-modal) > div {
  @media (max-width: $mediaBreakPoint-sml) {
    width: 100%;
    max-width: 100%;
  }
}

#dropzone-modal div[role='presentation']:not(#feedback-comment-modal, #choose-account-modal) > div > div > div {
  overflow-y: hidden;
}

// prevents text from being dragged over and selected on buttons, should not impact button clicks or other functionality
#root button {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
