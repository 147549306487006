.overlay {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  &:hover .shadow {
    opacity: 0.5;
  }

  &:hover .actions {
    opacity: 1;
  }

  .shadow {
    z-index: 1;
    transition: 0.5s ease;
    background-color: #7f7f7f;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 8px;
  }

  .visibleActions {
    opacity: 1 !important;
  }

  .actions,
  .visibleActions {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 8px;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .regeneration {
      all: unset;
      padding: 7px 14px 7px 14px;
      background: linear-gradient(to right, #77c0f5, #0073c7);
      border-radius: 20px;
      color: white;
      font-size: 0.9rem;
      z-index: 1;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .editImage {
      background-color: white !important;
      padding: 7px !important;
      height: 17px !important;
      border-radius: 50% !important;
      all: unset;
      color: black;
      z-index: 1;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .downloadIcon {
      background-color: white;
      position: absolute;
      display: flex;
      align-items: center;
      bottom: 10px;
      left: 10px;
      color: black;
      z-index: 1;
      cursor: pointer;
      padding: 3px 14px 3px 10px;
      border-radius: 100px;

      svg {
        margin: 5px;
      }

      span {
        font-size: 0.8rem;
      }
    }

    .feedback {
      position: absolute;
      bottom: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      z-index: 1;
      background-color: white;
      border-radius: 100px;

      .vl {
        border-left: 1px solid var(--color-gray-150);
        top: 2px;
        bottom: 2px;
        position: absolute;
        left: 50%;
      }

      hr {
        transform: rotate(90deg);
        background: black;
      }

      span {
        margin: 6px 14px 6px 14px;
        height: 18px;

        svg {
          cursor: pointer;
        }
      }
    }
  }
}

.expandedOverlay {
  z-index: 999;
  opacity: 0;

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.33);
  }
}
