.inputFieldStyle {
  div {
    border: none;
    background-color: #f1f3f5;
    box-shadow: none;

    input {
      min-height: 30px;
    }
  }
}
