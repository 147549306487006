.feedbackCommentModal {
  > div:first-child {
    border-radius: 12px;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    width: 480px;
    gap: 18px;

    .topSection {
      display: flex;
      gap: 12px;

      img.imageBox,
      video.imageBox {
        width: 100px;
        height: 100px;
        object-fit: cover;
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 12px;
        border-radius: 8px;
      }

      .instructions {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .instructionsHeading {
        font-size: 16px;
        font-weight: 700;
      }

      .instructionsText {
        font-size: 12px;
      }
    }

    .commentTextArea {
      div {
        border: none;
        background: none;

        textarea {
          min-height: 214px;
          background-color: var(--secondary-button-color-variant-2);
          color: var(--text-color-variant-1);
          border-radius: 12px;
          padding: 10px;
        }
      }
    }
  }
}
