.cancelButton {
  all: unset;
  height: 'fit-content';
  padding: 7px 14px 7px 14px;
  text-align: center;
  color: #0073c7;
  background: none;
  border-radius: 30px;
  font-size: 0.9rem;
  cursor: pointer;
  z-index: 99;
  //box-shadow: rgba(35, 47, 63, 0.3) 0px 0px 2px 0px, rgba(35, 47, 63, 0.15) 0px 1px 4px 0px;
  background-size: cover !important;
  white-space: nowrap;

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
}
