.imageEditTab {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: fit-content;
  width: 70vw;
  position: relative;
  width: 100%;
  height: 80vh;

  & .navigation {
    display: flex;
    flex-direction: column;
  }
  & .main {
    display: flex;
    flex-grow: 1;
    gap: 20px;
    width: 100%;

    & .controlsContainer {
      margin: 12px 24px;
      max-width: 400px;
    }

    & .imageContainer {
      border-radius: 16px;
      background: var(--Gray-Gray-25, #f8f9fa);
      padding: 44px clamp(24px, 5%, 44px);
      width: 100%;
      box-sizing: border-box;

      & img {
        border-radius: 10px;
        max-width: 100%;
        max-height: 60vh;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;

  & .liveImageIcon {
    height: 24px;
    width: 24px;
  }
}
