@import 'src/globalVariables';

.cardTextBlockWrapper {
  position: absolute;
  bottom: 0;
  min-height: 108px;
}

@media only screen and (max-width: $mediaBreakPoint-lrg) {
  .card {
    flex: 1 0 45% !important;
  }
}

@media only screen and (max-width: $mediaBreakPoint-sml) {
  .card {
    flex: 1 0 100% !important;
  }
}
