.rangeWrapper {
  margin-bottom: 24px;

  .rangeInput {
    accent-color: #4305f4;
    width: 100%;
    height: 15px;

    &:hover {
      cursor: grab;
    }
  }

  .rangeLabels {
    width: 100%;
    font-family: Amazon Ember;
    font-size: 13px;
    font-weight: 500;
    color: #5c687c;

    & .maxLabel {
      float: right;
    }
  }
}
