.FeedBackAlertComp {
  div[role='dialog'] {
    position: fixed !important;
    inset: auto !important;
    transform: translate(calc(-50% + 12px), 6px) !important;
  }
}
.DownloadAndSavetoALButtons {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
