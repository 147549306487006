@import 'src/globalVariables';

.tabbedNavigation {
  & li[role='presentation']::after {
    background-color: var(--AI-purple, #4305f4);
  }

  @media (max-width: $mediaBreakPoint-sml) {
    display: none;
  }
}
