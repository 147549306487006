.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .originalImage {
    max-width: 100%;
  }

  .reframeWrapper {
    border: 2px dashed var(--Gray-Gray-500, #8290a4);
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    width: auto;
    max-width: 100%;
    max-height: 60vh;
    background-color: var(--background-color-variant-3, #e6e9ed);
    overflow: hidden;
    transition: all 300ms ease-in-out;

    // need these overrides for when reframe edit mode is used
    img {
      max-width: 100% !important;
      max-height: 100% !important;
      border-radius: 0 !important;
    }
  }

  .resultsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .headerDetails {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .headerText {
          font-size: 16px;
          font-weight: 700;
          line-height: 31px;
          text-align: left;
        }
      }

      .resultButtons {
        display: flex;
        justify-content: space-between;

        .resetButton {
          margin-right: 8px;
        }

        .primaryButton:not(:disabled) {
          background-color: var(--AI-purple, #4305f4);
          color: var(--Gray-Gray-25, #f8f9fa);
        }
      }
    }
  }
}
