.image-list-wrapper {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.4) 5%,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4) 95%,
    rgba(255, 255, 255, 0.8)
  );
  display: grid !important;
  grid-auto-rows: 1fr;
  grid-template-areas:
    'item1 item1 item2 item3 item3 item4 item5'
    'item6 item7 item2 item8 item9 item10 item11';

  .image-list-item {
    z-index: -1;
  }
}

.container {
  position: relative;
  background-color: black;
  border-radius: 8px;

  .image {
    opacity: 1;
    display: block;
    width: 100%;
    min-height: 300px;
    object-fit: cover;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 90%;
  }

  &:hover .image {
    opacity: 0.3;
  }

  &:hover .middle {
    opacity: 1;
  }

  .text {
    color: white;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 14;
    -webkit-box-orient: vertical;
    font-weight: lighter;
    line-height: 1.2;
    width: 70%;
  }

  .leftButton {
    all: unset;
    font-size: 0.8rem;
    background-color: #0073c7;
    border-radius: 20px;
    color: white;
    padding: 7px 14px 7px 14px;
    margin-right: 10px;
    cursor: pointer;
  }

  .rightButton {
    all: unset;
    font-size: 0.8rem;
    background-color: #d9dee4;
    border-radius: 20px;
    color: black;
    padding: 7px 14px 7px 14px;
    cursor: pointer;
  }
}
