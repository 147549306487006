// global vars
@import 'src/globalVariables';

.imageModal {
  // modal background
  & div[role='presentation']:not(div[role='tabpanel'] div) {
    background: linear-gradient(106deg, rgba(98, 54, 255, 0.6) 11.04%, rgba(225, 35, 194, 0.6) 59.97%, rgba(254, 180, 36, 0.6) 100%);
    backdrop-filter: blur(10px);
  }

  & #image-modal-close-confirm div[role='presentation'] {
    background: black;
  }

  // override for some storm modal UI related elements that aren't customizable via props
  div[role='presentation'] > div > div > div:last-child:not(div[role='listbox']) {
    padding: 15px !important;
    max-height: unset !important;
  }

  h1 {
    width: 100%;
  }
}

.imageEditTab {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: fit-content;
  min-width: 100%;
  position: relative;

  & .navigation {
    display: flex;
    flex-direction: column;
  }

  & .main {
    display: flex;
    gap: 20px;
  }

  & .imageContainer {
    border-radius: 16px;
    background: var(--Gray-Gray-25, #f8f9fa);
    padding: 88px 88px 24px;
    min-width: fit-content;
    box-sizing: border-box;

    & img {
      border-radius: 10px;
    }
  }

  & .controlsContainer {
    margin: 12px 24px;
  }
}

.navigationHeader {
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;
  max-width: 95%;
  @media (min-width: $mediaBreakPoint-sml) {
    flex-direction: row;
    padding-left: 20px;
    max-width: 100%;
    gap: unset;
    justify-content: space-between;
    align-items: center;
  }
}

.buttons {
  display: flex;
  gap: 8px;
}

.saveButtons:not(:disabled) {
  border: 1.5px solid var(--AI-purple, #4305f4);
  background-color: var(--AI-purple, #4305f4) !important;
}

.saveToAdsAccountDropdown {
  display: flex;
  height: 31px;
  padding: 7px 14px;
  align-items: flex-start;
  gap: 7px;
}

.saveToAdsAccountDropdownItem {
  display: flex;
  width: 255px;
  align-items: flex-start;
  padding: 3px 14px !important; // override the default setting
  flex-direction: column;
  align-self: stretch;
}

.arrowNavigation {
  position: fixed;
  top: 90%;
  cursor: pointer;
  z-index: 701;

  display: flex;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 40px;
  background: rgba($color: #000000, $alpha: 0.1);

  @media (min-width: $mediaBreakPoint-sml) {
    top: 50%;
  }

  &.left {
    left: 1%;
    transform: rotate(180deg);
  }

  &.right {
    right: 1%;
  }
}
