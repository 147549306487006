.feedbackAlert {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 350px;
  color: white;
  padding: 1px;

  .header {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    p {
      font-weight: 700;
      font-size: 15px;
      line-height: 19px;
    }

    button {
      color: white;

      &:hover {
        color: white;
      }
    }
  }

  .body {
    font-size: 13px;
    line-height: 19px;
  }

  .footer {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
}
