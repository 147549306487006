.container {
  .header {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.15px;
    text-align: left;
  }

  .dropdownList {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
