.progress {
  display: flex;
  width: 100%;
  height: 4px;
  border-radius: 20px;
  background-color: var(--studio-core-colors-pure-white);
  overflow: hidden;

  .track {
    display: inline-flex;
    width: 100%;
    height: 4px;

    .bar {
      height: 100%;
      background: linear-gradient(#6236ff, #8631ee);
      border-radius: 20px;
      transition: width 0.2s ease;
    }

    $indeterminateWidth: 25%;

    &.indeterminate {
      animation-name: animateBackAndForth;
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      .bar {
        width: $indeterminateWidth;
      }
    }

    @keyframes animateBackAndForth {
      0% {
        transform: translateX(0%);
      }

      50% {
        transform: translateX(calc(100% - $indeterminateWidth));
      }

      100% {
        transform: translateX(0%);
      }
    }
  }
}
