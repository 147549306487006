// global vars
@import 'src/globalVariables';

.container {
  display: flex;
  flex-direction: row;

  .column {
    flex-direction: column;
  }

  .spaceBetween {
    justify-content: space-between;
  }

  @media (max-width: $mediaBreakPoint-mid) {
    display: block;
    margin-top: 40px;
  }
}

.assets-details {
  /* modal height - modal padding * 2 */
  max-height: calc(-13rem + 100vh - 20px);

  @media (max-width: $mediaBreakPoint-mid) {
    max-height: unset;
  }

  .content {
    border-radius: 20px;
    min-width: 180px;
    max-width: 900px;
    max-height: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-button-color-variant-2);
    overflow: hidden;

    @media (max-width: $mediaBreakPoint-mid) {
      display: block;
      min-width: unset;
      min-height: unset;
      max-width: 100%;
      max-height: auto;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .content > * {
    max-height: fit-content;
  }

  .information {
    margin: 34px 10px 34px 20px;
    max-height: 100%;
    min-width: 270px;

    @media (max-width: $mediaBreakPoint-mid) {
      margin: 34px 10px;
      margin-bottom: 10px;
      min-width: unset;

      button {
        width: 100%;
      }
    }

    .details {
      // max-height: calc(100% - 42px);
      overflow-x: hidden;
      overflow-y: auto;

      font-size: 16px;
      line-height: 1.5em;
      color: var(--text-color-variant-1);

      .header {
        // margin-bottom: 6px;
        font-size: 16px;
        line-height: 1.5em;
        font-weight: bold;
        color: var(--text-color-variant-1);
      }

      .detail {
        font-size: 15px;
        line-height: 1.5em;
        font-weight: 400;
        color: var(--text-color-variant-7);

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &.prompt {
          background-color: var(--secondary-button-color-variant-2);
          margin-top: 6px;
          padding: 13px;
          border-radius: 12px;
          overflow-y: auto;

          min-height: 80px;
          width: 270px;

          font-size: 14px;
          line-height: 1.5em;
          color: var(--text-color-variant-7);

          @media (max-width: $mediaBreakPoint-mid) {
            width: 100%;
          }
        }
      }
    }

    .details:not(:last-child) {
      margin-bottom: 26px;
    }
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 15px;
  cursor: pointer;
}

.modal {
  position: relative;
}
