.snackbarManager {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  z-index: 800;
  font-size: 16px;
  pointer-events: none;
  transition: background-color 0.25s ease;

  &.expanded {
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.75);
    pointer-events: auto;
  }

  .snackbarList {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .snackbarListItemContent {
    position: relative;
  }

  .snackbarListItem {
    pointer-events: auto;
    display: flex;
    flex-direction: column;

    &:last-child {
      .snackbar {
        cursor: pointer;
      }
    }

    .spacer {
      display: flex;
      height: 20px;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .snackbar {
      max-width: 100%;
      background-color: white;
    }

    .closeButton {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%) scale(0);
      width: 25px;
      height: 25px;
      background: rgb(0, 97, 169);
      overflow: hidden;
      opacity: 0;
      border-radius: 999px;
      transition:
        opacity 0.25s ease,
        transform 0.25s ease;
      pointer-events: none;
    }

    .badge {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%) scale(0);
      opacity: 0;
      transition:
        opacity 0.25s ease,
        transform 0.25s ease;
      pointer-events: none;
      padding-inline: 7px;
      border-radius: 999px;
      font-size: 13px;
    }
  }

  &:not(.expanded) {
    .snackbarListItem:last-child .badge.show {
      opacity: 1;
      transform: translate(50%, -50%) scale(1);
    }

    .snackbarListItem:last-child:hover {
      .closeButton {
        opacity: 1;
        pointer-events: auto;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  &.expanded {
    .snackbarListItem:hover {
      .closeButton {
        opacity: 1;
        pointer-events: auto;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  button {
    color: var(--AI-purple, #4305f4);
  }
}
