.asset-card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  height: 100%;

  &.fixed-size {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }

  .content {
    display: block;
    width: 100%;
    height: 100%;
    max-height: inherit;
    object-fit: cover;
    background-color: transparent;
    transition: transform 0.25s ease;
  }

  .image {
    backface-visibility: hidden;
    opacity: 0;
  }

  .video {
    min-height: inherit;
    margin-bottom: -4px; // TODO: find out why there's a weird gap below for some reason
    opacity: 0;
  }

  .videoIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.loaded .image,
  &.loaded .video,
  &.loaded .overlay {
    transition:
      transform 0.25s ease,
      opacity 0.5s ease;
    opacity: 1;
  }

  .placeholder {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
  }

  .placeholder.loading {
    position: absolute;
    top: 0;
  }

  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 20px;

    display: flex;
    background: rgba(0, 0, 0, 0.1);

    transition: opacity 0.5s ease;
    visibility: visible;
    opacity: 0;

    .info {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-grow: 1;
      visibility: hidden;
      transition: opacity 0.5s ease;
      opacity: 0;

      .text {
        color: white;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 14;
        -webkit-box-orient: vertical;
        font-weight: lighter;
        line-height: 1.2;
        margin-bottom: 6px;
      }
    }

    .icon {
      position: absolute;
      width: auto;
      height: auto;
      background: transparent;

      &.view {
        width: 70px;
        height: 70px;
        bottom: 20px;
        right: 20px;
      }
      &.video {
        width: 50px;
        height: 50px;
        top: 20px;
        left: 20px;
      }
    }
  }

  &.show-overlay:hover .content {
    transform: scale(1.1);
    z-index: 1;
  }

  &:hover .overlay {
    cursor: pointer;
    visibility: visible;
    opacity: 1;
    & .info {
      visibility: visible;
      opacity: 1;
    }
  }
}
