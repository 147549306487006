.radioGroupWrapper {
  margin-bottom: 24px;

  & .buttonGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    & .button {
      padding: 12px 16px;
      background-color: white;
      font-size: 14px;
      border: 2px solid;
      border-radius: 8px;
    }
  }
}
