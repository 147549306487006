@import 'src/globalVariables';

@media only screen and (max-width: $mediaBreakPoint-sml) {
  .description {
    flex: 1 1 100% !important;
    padding-bottom: 20px;

    p {
      font-size: 37px !important;
      font-weight: 700 !important;
      line-height: 34.2px !important;
      letter-spacing: 1px !important;
      text-align: left;
    }
  }
}
