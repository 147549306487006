.container {
  &:hover {
    & .item {
      border: 1px solid var(--AI-purple, #4305f4);
      cursor: pointer;
    }

    & .text {
      color: var(--AI-purple, #4305f4);
    }

    & path {
      fill: var(--AI-purple, #4305f4);
    }

    & div[data-id='restyle-icon'] path:not([fill='#232F3F']),
    & div[data-id='remix-icon'] path:not([fill='#F8F9FA']),
    & div[data-id='live-image-icon'] path:not([fill='#F8F9FA']) {
      stroke: var(--AI-purple, #4305f4);
      fill: none !important;
      stroke-opacity: 1;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--Desktop-spacing-small, 10px);
  width: 50px;
  height: 50px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  background: var(--Gray-Gray-25, #f8f9fa);
}

.image {
  object-fit: contain;
}

.text {
  align-self: stretch;
  color: var(--Gray-Gray-700, #5c687c);
  text-align: center;
  font-family: 'Amazon Ember';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.active {
  & .item {
    border: 1px solid var(--AI-purple, #4305f4);
  }

  & .text {
    color: var(--AI-purple, #4305f4);
  }

  & path {
    fill: var(--AI-purple, #4305f4);
  }

  & div[data-id='restyle-icon'] path:not([fill='#232F3F']),
  & div[data-id='remix-icon'] path:not([fill='#F8F9FA']),
  & div[data-id='live-image-icon'] path:not([fill='#F8F9FA']) {
    stroke: var(--AI-purple, #4305f4);
    fill: none !important;
    stroke-opacity: 1;
  }
}
